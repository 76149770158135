import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Balance 4×4`}</p>
    <p>{`OHS 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Bike`}</p>
    <p>{`15 Power Snatch (95/65)`}</p>
    <p>{`15 T2B`}</p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up this Saturday!  Come out
and cheer on all our teams!  We will be closing after the 4:30 class on
Friday to set up.  If you can help out please let us know, it will be
greatly appreciated.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s (11:15) and Sunday’s (1:45)
each weekend after our CrossFit classes.  Email Daniel for more
information.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      